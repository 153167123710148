<template>
	<div class="flex w-full justify-between pl-1">
		<div>
			<el-popconfirm
				v-if="props.isLogout"
				title="Do you want to log out ?"
				@confirm="confirmEvent"
			>
				<template #reference>
					<el-button type="primary" class="btn-header">
						<i class="ph-power"></i>
					</el-button>
				</template>
			</el-popconfirm>
			<el-button
				v-if="props.isGoBack"
				type="primary"
				class="btn-header"
				@click="$router.back()"
			>
				<i class="ph-caret-left"></i>
			</el-button>
		</div>
		<img src="../../assets/avr-logo.svg" width="80" class="mr-2" />
	</div>
</template>

<script setup>
import { defineProps } from "vue"
import { removeToken } from "../../utils/storage"

const props = defineProps({
	isLogout: Boolean,
	isGoBack: Boolean,
})

const confirmEvent = () => {
	removeToken()
	location.replace("/")
}
</script>
